import {client} from '../api/client'
import * as actions from "../actions/actiontypes"
import store from '../store'
const initialState = []

const libraryReducer = (state = initialState, action)=> {
    switch(action.type) {
        case actions.LIBRARY_LOAD:
        return action.payload
        default:
            return state;
    }
}
// Thunk function
export async function fetchLibrary(dispatch, getState) {
    const response = await client.get("library.json",{ headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }})
    dispatch({
        type: actions.LIBRARY_LOAD, payload: response.library.map(item => {
            let cover = ''
            //if (store.getState().recent.data.product) cover = "https://web.storyworld.international/"+ store.getState().recent.data.product +"/8I5RAGGN2U40Q0/Books/dist/" + item.path + "/images/thumbnail.jpg"
            cover = `https://web.storyworld.international/${process.env.REACT_APP_THUMBNAIL_SRV_VERSION}/8I5RAGGN2U40Q0/Books/dist/${item.path}/images/thumbnail.jpg`
            return { path: item.path, title: item.title.EN, cover }
        })
    })
}

export default libraryReducer;