import React from "react";

// Navigation bar
export default function Header() {
  return (
    <>
      <div
        className="position-sticky top-0 z-10 px-2"
        style={{ background: "#6059f7" }}
      >
        <div className="container">
          <div className="d-flex flex align-items-center justify-content-between py-2">
            <a href={process.env.REACT_APP_LIBRARY_APP_URL}>
              <img
                className="me-3"
                alt="storyworld logo"
                src="https://next.storyworld.io/_next/image?url=%2Fstoryworld-home.png&w=256&q=75"
                // style="color: transparent; height: 40px; margin-right: 14px;"
                style={{
                  color: "transparent",
                  height: "40px",
                  marginRight: "14px",
                }}
              />
            </a>
            <div className="d-flex flex flex-grow-1 align-items-center justify-content-between pl-6">
              <div className="d-flex gap-2">
                <a
                  className="px-2 py-3 text-white text-decoration-none fw-semibold"
                  href={process.env.REACT_APP_LIBRARY_APP_URL}
                >
                  Library
                </a>
                <a
                  className="px-2 py-3 text-white text-decoration-none fw-semibold"
                  href="https://dashboard2.storyworld.io/#/phonics/"
                >
                  Phonics Units
                </a>
                <a
                  className="px-2 py-3 text-white text-decoration-none fw-semibold"
                  href="https://dashboard2.storyworld.io"
                >
                  Dashboard
                </a>
                <a
                  className="px-2 py-3 text-white text-decoration-none fw-semibold"
                  href={`https://swlb.storyworld.io/${process.env.REACT_APP_THUMBNAIL_SRV_VERSION}/8I5RAGGN2U40Q0/?l=c2Nob29s&s=XzFmNDMyNjMyY2MzYTQ0OWE4ODZkODQ3NGMwYTc4NGZh&u=JKBK0GbYifjsxwU#/worksheets`}
                >
                  Resources
                </a>
                <a
                  className="px-2 py-3 text-white text-decoration-none fw-semibold"
                  href="https://shop.storyworld.us/"
                >
                  Store
                </a>
              </div>
              {/* <a className="d-flex flex align-items-center gap-3 rounded-3 px-5 py-2 text-decoration-none"
                    style="background: #fac515" target="_blank" href="https://www.storyworld.io/howtoupgrade"><span
                        className="fw-semibold" style="color: #404968;">Upgrade</span></a> */}
              <div className="d-flex flex align-items-center gap-2">
                <div className="d-flex flex gap-1">
                  <a
                    target="_self"
                    className="px-2 py-3 text-white text-decoration-none fw-semibold"
                    href="https://next.storyworld.io/home"
                  >
                    <span>Account &amp; Roster</span>
                  </a>

                  <a
                    target="_blank"
                    className="px-2 py-3 text-white text-decoration-none fw-semibold"
                    href="https://storyworld.zendesk.com/hc/en-us"
                  >
                    <span>Help</span>
                  </a>
                </div>
                <a
                          onClick={()=>{
                            const signOutURL = 'https://nestback.storyworld.io/api/v1/auth/logout';
                            const redirectURL = 'https://next.storyworld.io/login';
                            fetch(signOutURL, {
                              credentials: 'include',
                              method: 'POST',
                              body: JSON.stringify({}),
                            }).then(async (response) => {
                              if(response.ok){
                                window.location.href = redirectURL
                              }
                                
                                //location.href = redirectURL
                            }).catch((e)=>{

                            })                            
                            .finally(()=>{
                              window.location.href = redirectURL
                            })        
                          }}
                  className="px-2 py-3 text-white text-decoration-none fw-semibold fs-5"
                  href="#"
                >
                  Log out
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
